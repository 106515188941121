// extracted by mini-css-extract-plugin
export var mobileTitle = "TamarindChutney-module--mobileTitle--2lLRw";
export var mobileTitleTwo = "TamarindChutney-module--mobileTitleTwo--z_YsM";
export var tc = "TamarindChutney-module--tc--plObl";
export var tcAbout = "TamarindChutney-module--tcAbout--25zjr";
export var tcAboutContent = "TamarindChutney-module--tcAboutContent--20tg8";
export var tcBenefits = "TamarindChutney-module--tcBenefits--3wVZV";
export var tcProblem = "TamarindChutney-module--tcProblem--1Nhmr";
export var tcProblemContent = "TamarindChutney-module--tcProblemContent--2ABrV";
export var tcProblemContentSubtitle = "TamarindChutney-module--tcProblemContentSubtitle--3u0Bf";
export var tcProblemProfile = "TamarindChutney-module--tcProblemProfile--2_3mz";
export var tcProblemProfileDescription = "TamarindChutney-module--tcProblemProfileDescription--kbHOS";
export var tcProblemProfileName = "TamarindChutney-module--tcProblemProfileName--2dc3P";
export var tcProblemProfilePosition = "TamarindChutney-module--tcProblemProfilePosition--3IJaL";
export var tcSolution = "TamarindChutney-module--tcSolution--1e3tG";