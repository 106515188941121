import React from "react"
import Layout from "@src/components/Layout"
import TamarindLogo from "@src/icons/case-studies/tamarindChutney.png"
import TamarindAbout from "@src/icons/case-studies/tamarindChutneyAbout.png"
import TamarindProfile from "@src/icons/case-studies/tanvi-tamarind.png"
import TamarindSolution from "@src/icons/case-studies/tamarind-chutney-solution.png"
import TamarindAdditionalBenefits from "@src/icons/case-studies/tamarind-chutney-additional-benefits.png"

import SplashWithMenu from "../components/SpashWithMenu/SplashWithMenu"
import Row from "../../../V2/components/Row"
import DotBG from "../../../V2/components/DotBG"
import ImageRow from "../../../V2/Features/components/ImageRow/ImageRow"
import Callout from "../../../../components/Callout/Callout"
import OG from "@src/icons/og/tamarind-og.jpg"
import * as styles from "./TamarindChutney.module.scss"

const IndexPage = ({ location }) => {
  return (
    <Layout
      title="Tamarind Chutney Case Study"
      description="Tamarind Chutney automates the return & exchange process for their Shopify Store."
      url="https://canonic.dev/case-studies/tamarind-chutney"
      ogImage={OG}
      location={location}
    >
      <SplashWithMenu
        subtitle={"Case Study"}
        logo={TamarindLogo}
        title={
          <>
            Tamarind Chutney <br /> automates the return & exchange process for
            their Shopify Store.
          </>
        }
        stats={STATS}
        homepage={"https://tamarindchutney.in/"}
      />
      <Row className={styles.tc} id="about">
        <DotBG />
        <section className={styles.tcAbout}>
          {/* <h2>About the brand</h2> */}
          <ImageRow
            screenshot={TamarindAbout}
            title={"About the brand"}
            subtitle={
              <>
                <p>
                  <strong>Tamarind Chutney </strong>is a retail fashion brand
                  focusing on sustainably-sourced ethnic wear. Started by two
                  school friends with the aim to change the way they see
                  fashion.
                </p>
                <p>
                  This sassy clothing brand is committed to
                  <strong> equity </strong>
                  and <strong>sustainability</strong>. By working with artisans,
                  they ensure that India's rich craft heritage is preserved and
                  that their livelihoods are improved meaningfully.
                </p>
              </>
            }
            className={styles.tcAboutContent}
            reverse
          />
        </section>
        <span id="problem" />
      </Row>
      <Row>
        <section className={styles.tcProblem}>
          <div className={styles.tcProblemProfile}>
            <figure className={styles.tcProblemProfilePicture}>
              <img src={TamarindProfile} />
            </figure>
            <h4 className={styles.tcProblemProfileName}>Tanvi Bikhchandani</h4>
            <h5 className={styles.tcProblemProfilePosition}>
              Co-Founder, Tamarind Chutney
            </h5>
            <p className={styles.tcProblemProfileDescription}>
              Tanvi graduated from Columbia University in New York, where she
              studied Economics and South Asian studies. She is the Co-founder
              of Tamarind Chutney, a social enterprise that aims to improve
              artisan livelihoods and reduce textile waste.
            </p>
          </div>
          <div className={styles.tcProblemContent}>
            <h3>The Problem</h3>
            <p>
              Being a retail fashion brand, the return and exchange of products
              are one of the many day-to-day tasks for
              <strong> Tamarind Chutney. </strong> They followed a manual and
              intensive process to handle all these returns and exchanges.
            </p>
            <p>
              They followed a manual and intensive process to handle all these
              returns and exchanges.
            </p>
            <p className={styles.tcProblemContentSubtitle}>
              <strong>Pretty tedious!</strong>
            </p>
            <p>
              Tamarind Chutney needed an automated tool that streamlined and
              optimize their Shopify store’s returns and exchanges, allowing end
              users to manage workflows effectively and efficiently.
            </p>
          </div>
        </section>
      </Row>
      <Row id="solution">
        <DotBG />
        <section>
          {/* <h2 className={styles.mobileTitle}>The Solution</h2> */}
          <ImageRow
            screenshot={TamarindSolution}
            title={"The Solution"}
            subtitle={
              <>
                <p>
                  A support desk tool for enhanced simplicity and speed in
                  orchestrating returns/exchanges.
                </p>
                <p>
                  Canonic delivered an admin panel tool that semi-automates the
                  return and exchange process for the Shopify online store of
                  Tamarind Chutney. The functionality of the panel includes the
                  automation of:
                </p>
                <ul>
                  <li>Returns and exchanges for an entire order</li>
                  <li>
                    Returns and exchanges for particular products in an order
                  </li>
                  <li>Automated communication emails to users</li>
                </ul>
              </>
            }
            className={styles.tcSolution}
            reverse
          />
        </section>
      </Row>
      <Row>
        <section>
          {/* <h2 className={styles.mobileTitleTwo}>Additional Benefits</h2> */}
          <ImageRow
            screenshot={TamarindAdditionalBenefits}
            title={"Additional Benefits"}
            subtitle={
              <>
                <p>
                  Additionally, this admin panel tool automates the manual
                  search of the order by our agent and provides users direct
                  control to start the return/exchange process for their orders,
                  thus optimizing their Shopify e-commerce.
                </p>
                <p>
                  Integrations with Whatsapp and Insights Dashboard are now
                  available to evaluate the frequency and efficiency of returns
                  and exchanges, and communication with Delhivery and Shiprocket
                  is also now straightforward.
                </p>
              </>
            }
            className={styles.tcBenefits}
          />
        </section>
      </Row>
      <Callout location={location} />
    </Layout>
  )
}

export default IndexPage

const STATS = [
  {
    stat: "100+",
    subtitle: "HOURS SAVED",
  },
  {
    stat: "65%",
    subtitle: "MAN POWER SAVED",
  },
  {
    stat: "65%",
    subtitle: "TIME TO BUILD",
  },
  {
    stat: "9 lac",
    subtitle: "MONEY SAVED",
  },
]
